import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';

import Auth from "./pages/Auth";
import Home from "./pages/Home";
import StravaRedirect from "./pages/StravaRedirect";
import Ranking from "./pages/Ranking";

function App() {
    return (
        <AuthContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Auth />} exact={true} />
              <Route path="/home" element={<Protected><Home /></Protected>}/>
              <Route path="/redirect/exchange_token" element={<StravaRedirect />} />
              <Route path="/ranking" element={<Protected><Ranking /></Protected>}/>
            </Routes>
          </BrowserRouter>
        </AuthContextProvider>
    );
}
export default App;
