import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDLv--rPhojyXk9uTzPuIMWBvsCgT51W-U",
    authDomain: "av-challenges.firebaseapp.com",
    projectId: "av-challenges",
    storageBucket: "av-challenges.appspot.com",
    messagingSenderId: "604177388339",
    appId: "1:604177388339:web:1d37c63df051ce7dc36446"
};

const app = initializeApp(firebaseConfig);

export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
    hd: "activerse.app"
});
export const db = getFirestore(app);
export const auth = getAuth(app);
export default app;
