import React from "react";
import {UserAuth} from "../context/AuthContext";

const { REACT_APP_CLIENT_ID, REACT_APP_STRAVA_REDIRECT_URL } = process.env;

const handleLogin = () => {
    window.location = `http://www.strava.com/oauth/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${REACT_APP_STRAVA_REDIRECT_URL}/exchange_token&scope=read,activity:read`;
};

const Home = () => {
    const { logOut, user } = UserAuth();

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (err){
            console.error(err);
        }
    };

    return (
        <div>
            <h1>Welcome {user.displayName} at activerse challenge</h1>
            <button onClick={handleLogin}>Connect with Strava</button>
            <button onClick={handleSignOut}> logOut</button>
        </div>
    );
};

export default Home;