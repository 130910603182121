import React, {useEffect} from "react";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom"
import {db} from '../utils/firebase.js';
import { collection, doc, addDoc, setDoc } from "firebase/firestore";
import {UserAuth} from "../context/AuthContext";

import { getAuthToken } from "../utils/strava";

const StravaRedirect = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = UserAuth();
    const navigate = useNavigate();

    const authenticate = async () => {
        try {
            const response = await getAuthToken(searchParams.get('code'));
            console.log(response);

            const strava_token = { ... response };
            delete strava_token.athlete;

            const athlete = { ...response.athlete, strava_token}

            const docRef = await setDoc(
                doc(db, "users", user.uid), athlete, { merge: true }
            );
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        authenticate();
        navigate('/ranking')
    }, []);

    return <div>Loading</div>;
}

export default StravaRedirect;
