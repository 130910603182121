import React, { useState, useEffect } from 'react';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export const Auth = () => {
    const { googleSignIn, user } = UserAuth();
    const navigate = useNavigate();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        console.log('Auth: ', user);
        if (user != null) {
            navigate('/home');
        }
    }, [user]);

    return (
        <div>
            <GoogleButton onClick={handleGoogleSignIn} />
        </div>
    );
};

export default Auth;
