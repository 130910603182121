import {useEffect, useState} from "react";
import {UserAuth} from "../context/AuthContext";
import {getDoc, doc, collection, getDocs, query, orderBy, where} from "firebase/firestore";
import {db} from "../utils/firebase";

const Ranking = () => {

    const { logOut, user } = UserAuth();
    const [ athlete, setAthlete ] = useState({});
    const [ ranking, setRanking ] = useState({});
    const [ loading, setLoading ] = useState(true);

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (err){
            console.error(err);
        }
    };

    const getAthleteData = async (userId) => {
        const docSnap = await getDoc(doc(db, "users", userId));

        if (docSnap.exists()) {
            console.log(docSnap.data());
            setAthlete(docSnap.data());
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }

        return {};
    }

    const getRankingList = async () => {

        // get all doc from collection summaries ordered by all field
        const summariesRef = collection(db, "summaries");
        const q = query(summariesRef, orderBy("all", "desc"));
        const summaries = await getDocs(q);

        const list = [];
        await summaries.forEach((summary) => {
            list.push(summary.data());
        });

        setRanking(list);
    }

    useEffect(() => {
        getAthleteData(user.uid);
        const r = getRankingList().then((rankingList) => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return 'Loading...';
    }


    return (

        <div>
            <h1>Welcome {athlete.firstname} at activerse challenge 2023 <button onClick={handleSignOut}>Log out</button></h1>
            <img src={athlete.profile} alt={athlete.username} />

            <h2>Ranking</h2>
            <table>
                <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Ride</th>
                    <th>Run</th>
                    <th>Walk</th>
                    <th>Swim</th>
                    <th>Total points</th>
                </tr>
            {ranking.map((summary, index) => {
                return (
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{summary.athlete.firstname} {summary.athlete.lastname}</td>
                        <td align="right">{Math.round(summary.Ride)}</td>
                        <td align="right">{Math.round(summary.Run)}</td>
                        <td align="right">{Math.round(summary.Walk)}</td>
                        <td align="right">{Math.round(summary.Swim)}</td>
                        <td align="right">{Math.round(summary.all)}</td>
                    </tr>
                )
            })}

            </table>
        </div>
    );
};

export default Ranking;
