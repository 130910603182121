import { useContext, createContext, useEffect, useState } from 'react';
import { signInWithRedirect, signOut, onAuthStateChanged} from 'firebase/auth';
import { auth, googleProvider } from '../utils/firebase';

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const googleSignIn = () => {
        signInWithRedirect(auth, googleProvider)
    };

    const logOut = () => {
        signOut(auth)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setLoading(false);
            setUser(currentUser);

            // if (currentUser) {
            //     const userDb = {
            //         id: currentUser.uid,
            //         name: currentUser.displayName,
            //         email: currentUser.email,
            //         photoURL: currentUser.photoURL
            //     };
            //
            //     await setDoc(
            //         doc(db, "users", userDb.id), userDb, {merge: true}
            //     );
            // }
            //
            // console.log('User', currentUser)
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <AuthContext.Provider value={{ googleSignIn, logOut, user }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(AuthContext);
};
